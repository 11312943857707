import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import fa from "vuetify/lib/locale/fa"; // فایل زبان فارسی
import VueEvents from "vue-event-handler";

Vue.use(VueEvents);
Vue.use(Vuetify);

export default new Vuetify({
  rtl: true,
  breakpoint: {
    thresholds: {
      xs: 600,
      sm: 960, //600px > < 960px
      md: 1536, //960px > < 1264px
      lg: 1904, //1264px > < 1904px*
      //> 1904px*
    },
  },
  lang: {
    locales: { fa },
    current: "fa",
  },
  theme: {
    themes: {
      light: {
        // primary: "#363636",
        // accent: "#F9A825"
      },
    },
  },
});
