var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showList(_vm.task.checkList)),expression:"showList(task.checkList)"}],staticClass:"task-checklist"},[_c('div',{staticClass:"progress"},[_c('v-progress-linear',{attrs:{"value":_vm.completion}})],1),_vm._l((_vm.task.checkList),function(item){return _c('div',{key:item.id,staticClass:"item",on:{"mouseover":function($event){_vm.showButtons = item.id},"mouseleave":function($event){_vm.showButtons = null}}},[_c('div',{staticClass:"parent"},[_c('div',{staticClass:"check"},[_c('div',{staticClass:"pretty p-default"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.checked),expression:"item.checked"}],attrs:{"id":item.id,"type":"checkbox"},domProps:{"checked":Array.isArray(item.checked)?_vm._i(item.checked,null)>-1:(item.checked)},on:{"change":[function($event){var $$a=item.checked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(item, "checked", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(item, "checked", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(item, "checked", $$c)}},function($event){return _vm.toggleCheckItem(item)}],"click":function($event){$event.stopPropagation();}}}),_c('div',{staticClass:"state p-primary"},[_c('label',{attrs:{"for":item.id},domProps:{"innerHTML":_vm._s(_vm.linkifyHtml(item.name))}})])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showButtons === item.id),expression:"showButtons === item.id"}],staticClass:"right"},[_c('v-icon',{attrs:{"small":""},on:{"click":(event) => {
              event.stopPropagation();
              _vm.selectedItem = item;
              _vm.onConvert();
            }}},[_vm._v(" mdi-format-list-bulleted ")]),_c('v-icon',{attrs:{"color":"error","small":""},on:{"click":(event) => {
              event.stopPropagation();
              _vm.selectedItem = item;
              _vm.onDelete();
            }}},[_vm._v(" mdi-delete ")])],1),_c('div',{staticClass:"clear"})])])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }