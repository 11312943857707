var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticStyle:{"background-color":"transparent"},attrs:{"dark":""}},[_c('v-list',{staticStyle:{"background-color":"#f3f3f3"},attrs:{"dense":"","light":""}},[_c('v-subheader',[_c('v-btn',{attrs:{"block":"","dark":"","color":_vm.setColor},on:{"click":function($event){return _vm.$router.push({
            name: 'Letter',
            params: {
              create: true,
              pagetype: 'AllMessage',
              messagetype: 4,
              id: 0,
            },
          })}}},[_vm._v(" نامه جدید ")])],1),_c('v-list-group',{staticStyle:{"font-size":"0.6rem !important"},attrs:{"color":"secondary","value":false,"dense":"","id":"send-shortkey"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-send-outline")]),_c('v-list-item-title',{staticStyle:{"font-size":"0.7rem !important"}},[_vm._v(" ارسال شده ها ")])]},proxy:true}])},[_c('v-list-item',[_c('v-list-item-title',{staticStyle:{"font-size":"0.7rem !important"},on:{"click":function($event){return _vm.$router.push({
              name: 'DomesticLetters',
            })}}},[_vm._v(" نامه های داخلی ")])],1),_c('v-list-item',[_c('v-list-item-title',{staticStyle:{"font-size":"0.7rem !important"},on:{"click":function($event){return _vm.$router.push({
              name: 'IncomingLetters',
            })}}},[_vm._v(" نامه های وارده ")])],1),_c('v-list-item',[_c('v-list-item-title',{staticStyle:{"font-size":"0.7rem !important"},on:{"click":function($event){return _vm.$router.push({
              name: 'LettersIssued',
            })}}},[_vm._v(" نامه های صادره ")])],1)],1),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({
          name: 'MyMessage',
        })}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-eye")])],1),_c('v-list-item-title',{staticStyle:{"font-size":"0.7rem !important"}},[_vm._v(" کارتابل نامه های من ")])],1),_c('v-divider'),(this.$store.state.CurrentUser.permissions.includes('AllMessages'))?_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({
          name: 'AllMessage',
        })}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-email-newsletter")])],1),_c('v-list-item-title',{staticStyle:{"font-size":"0.7rem !important"}},[_vm._v(" کلیه نامه ها ")])],1):_vm._e(),(this.$store.state.CurrentUser.permissions.includes('AllMessages'))?_c('v-divider'):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({
          name: 'DraftMessage',
        })}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-draw-pen")])],1),_c('v-list-item-title',{staticStyle:{"font-size":"0.7rem !important"}},[_vm._v(" پیش نویس نامه ها ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }