var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],class:{
    'tiptap-editor-view': true,
    border: !_vm.noBorder,
    'no-border': _vm.noBorder,
  },on:{"click":_vm.focus}},[(_vm.bubble)?_c('rich-editor-menu-bubble',{attrs:{"editor":_vm.editor}}):_vm._e(),(_vm.floating)?_c('rich-editor-floating-menu',{attrs:{"menu":"","editor":_vm.editor}}):_vm._e(),(!_vm.hideToolbar)?_c('rich-editor-menu-bar',{staticClass:"menu-bar",attrs:{"editor":_vm.editor}}):_vm._e(),_c('editor-content',{class:{
      editor: true,
      dense: _vm.dense,
    },style:(_vm.getStyle(_vm.maxHeight)),attrs:{"editor":_vm.editor}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }