var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"task-properties"},[_c('select-date',{attrs:{"reminder":false},on:{"select":_vm.onSelectDueDate},model:{value:(_vm.showSelectDueDate),callback:function ($$v) {_vm.showSelectDueDate=$$v},expression:"showSelectDueDate"}}),_c('select-date',{attrs:{"reminder":false},on:{"select":_vm.onSelectStartDate},model:{value:(_vm.showSelectStartDate),callback:function ($$v) {_vm.showSelectStartDate=$$v},expression:"showSelectStartDate"}}),_c('v-subheader',[_vm._v(_vm._s(_vm.$t("Description")))]),_c('div',{staticClass:"description"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
        !_vm.editDescription && _vm.task.description && _vm.task.description.length > 0
      ),expression:"\n        !editDescription && task.description && task.description.length > 0\n      "}],staticClass:"elevation-1 pa-4",on:{"click":_vm.startEditDescription}},[_c('div',{staticClass:"tiptap-editor-view",domProps:{"innerHTML":_vm._s(_vm.linkifyHtml(_vm.task.description))}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.task.description && !_vm.editDescription),expression:"!task.description && !editDescription"}],staticClass:"elevation-1 pa-4",on:{"click":_vm.startEditDescription}},[_vm._v(" "+_vm._s(_vm.$t("No description"))+" ")]),(_vm.editDescription)?_c('div',[_c('rich-editor',{ref:"description",on:{"submit":_vm.updateDescription,"click-outside":_vm.updateDescription},model:{value:(_vm.task.description),callback:function ($$v) {_vm.$set(_vm.task, "description", $$v)},expression:"task.description"}}),_c('v-btn',{attrs:{"icon":"","text":""},on:{"click":_vm.updateDescription}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check-circle ")])],1),_c('v-btn',{attrs:{"icon":"","text":""},on:{"click":_vm.cancelUpdateDescription}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close-circle ")])],1)],1):_vm._e()]),_c('v-subheader',[_vm._v(_vm._s(_vm.$t("Dates"))+" ")]),_c('v-list',{staticClass:"elevation-1",attrs:{"two-line":""}},[_c('v-list-item',{on:{"click":function($event){_vm.showSelectStartDate = true}}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-calendar-today")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("Start date")))]),_c('v-list-item-subtitle',[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.task.startDateOnUtc),expression:"task.startDateOnUtc"}]},[_vm._v(_vm._s(_vm.formatDate(_vm.task.startDateOnUtc)))])])],1),(
          _vm.task.reminderStartDate !== 'never' && _vm.task.reminderStartDate != null
        )?_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-alarm-check")])],1):_vm._e(),_c('v-list-item-action',[_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onSelectStartDate(null)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)],1),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){_vm.showSelectDueDate = true}}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-alarm")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("End date")))]),_c('v-list-item-subtitle',[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.task.dueDateOnUtc),expression:"task.dueDateOnUtc"}]},[_vm._v(_vm._s(_vm.formatDate(_vm.task.dueDateOnUtc)))])])],1),(
          _vm.task.reminderDueDate !== 'never' && _vm.task.reminderDueDate != null
        )?_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-alarm-check")])],1):_vm._e(),_c('v-list-item-action',[_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onSelectDueDate(null)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)],1)],1),(_vm.isEstimationEnabled)?[_c('v-subheader',[_vm._v(_vm._s(_vm.$t("Estimations")))]),_c('task-estimations-in-detail',{attrs:{"task":_vm.task}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }